<template>
    <Sections v-if="!currentUser?.user_welcome">
        <AppSubheader title="Home" :dark="true">
            <ButtonSubheader
                :buttonIcon="currentUser?.eligible_for_aaa_free_trial ? 'IconTag' : null"
                :buttonText="currentUser?.eligible_for_aaa_free_trial ? 'Free Trial' : 'Membership'"
                :buttonRoute="useContextConversion().routeMembership('header')"
                :dark="true"
            ></ButtonSubheader>
        </AppSubheader>
        <SectionStreamJumbo
            v-if="isLoggedIn && liveNow"
            :stream="liveNow"
        ></SectionStreamJumbo>
        <SectionJumbo
            v-else-if="isLoggedIn && featuredCourse"
            :image="featuredCourse.course_jumbo_image_url"
            imageAlt="The main image from one of Andy Guitar's fantastic online guitar courses"
            :imagePosition="featuredCourse.course_jumbo_image_position"
            :heading="featuredCourse.course_title"
            padding="py-12 sm:py-16 lg:py-20"
            headingColor="text-white"
            :content="`<p>${featuredCourse.lesson_title}</p>`"
            contentColor="text-zinc-200"
            :buttonText="featuredCourse.percent_complete === 0 ? 'Get Started' : 'Resume'"
            :buttonRoute="useContextLesson().route(featuredCourse, 'continue', featuredCourse.course_slug)"
            :fadeToBlack="true"
            :textCentered="false"
        >
            <template v-slot:custom>
                <div class="text-white mt-10 md:mt-20" v-if="featuredCourseProgress">
                    <p class="mb-3">{{ featuredCourseProgress }}% Complete</p>
                    <ContentProgressLinear :value="featuredCourseProgress"></ContentProgressLinear>
                </div>
            </template>
        </SectionJumbo>
        <SectionJumbo
            v-if="isLoggedIn === false"
            padding="py-10 md:py-16"
            image="https://d1ma7tzlswibx6.cloudfront.net/images-course/youtube-header-option-2-1.jpg"
            imageAlt="The main image from Andy Guitar's fantastic online beginner guitar course"
            imageOpacity="opacity-30"
            heading="Absolute Beginner Guitar"
            headingColor="text-white"
            content="<p>Help with basic skills such as tuning your guitar, reading chord diagrams and chord sheets.</p>"
            contentColor="text-white"
            buttonRoute="/videos/new-guitarist-start-here?context=course&courseSlug=absolute-beginner-guitar"
            buttonText="Get Started"
            :textCentered="false"
        ></SectionJumbo>
        <SectionJumbo v-if="isLoggedIn === null || (isLoggedIn && !featuredCourse)">
            <template v-slot:custom>
                <LoadingSpinner />
            </template>
        </SectionJumbo>
        <SectionCookies></SectionCookies>
        <SectionKeepPracticing
            v-if="isLoggedIn && viewed"
            color="even:bg-zinc-50"
            :lessons="viewed"
        ></SectionKeepPracticing>
        <SectionMyList
            v-if="isLoggedIn"
            color="even:bg-zinc-50"
            :lessons="userLessons"
        ></SectionMyList>
        <SectionAbsoluteBeginnerGuitar
            color="even:bg-zinc-50"
            :lessons="absoluteBeginnerGuitarLessons"
        ></SectionAbsoluteBeginnerGuitar>
        <SectionSongsRecent
            color="even:bg-zinc-50"
            :lessons="songsRecent"
        ></SectionSongsRecent>
        <SectionPathwayBeginnerAcoustic
            color="even:bg-zinc-50"
            :pathway="beginnerAcousticPathway"
        ></SectionPathwayBeginnerAcoustic>
        <SectionPathwayIntermediateAcoustic
            v-if="intermediateAcousticPathway"
            color="even:bg-zinc-50"
            :pathway="intermediateAcousticPathway"
        ></SectionPathwayIntermediateAcoustic>
        <SectionLiveReplays
            color="even:bg-zinc-50"
            :lessons="liveReplays"
        ></SectionLiveReplays>
        <SectionStreams
            color="even:bg-zinc-50"
            :streams="streams"
            heading="Live Streams"
        ></SectionStreams>
        <SectionText
            class="even:bg-zinc-50"
            heading="Free Resources"
            headingComponent="h2"
            :content="isLoggedIn ? '<p>Your Andy Guitar account includes free ebooks and backing tracks.</p>' : '<p>Create a free account to claim ebooks and backing tracks.</p>'"
            buttonText="Resources"
            buttonRoute="/free-resources"
            buttonIcon="IconBook"
        ></SectionText>
        <SectionTestimonials
            color="bg-zinc-100"
            v-if="!isLoggedIn"
            id="home-testimonials"
            heading="What our students say"
            :testimonials="testimonials"
            headingComponent="h2"
        ></SectionTestimonials>
        <SectionJumbo
            v-if="!isLoggedIn"
            padding="py-20 sm:py-24 lg:py-32"
            :image="$config.public.uploads + 'images-general/online-guitar-lessons-ukulele-lessons-andy-guitar.jpg'"
            :textCentered="false"
            :textRight="false"
            buttonText="Learn About Andy Here"
            content="Andy has helped thousands of people learn guitar, find out what drives him here"
            contentColor="text-white"
            heading="About Andy"
            headingComponent="h2"
            buttonRoute="/about-andy"
            headingColor="text-white"
            imageAlt="Image of Andy Crowley, online guitar teacher"
            imageOpacity="opacity-40"
            imageLoading="lazy"
            fetchPriority="low"
        >
        </SectionJumbo>
        <SectionAccordion
            v-if="!isLoggedIn"
            color="bg-zinc-800"
            heading="Frequently asked questions"
            heading-color="text-white"
            headingComponent="h3"
            :items="questions"
            itemColor="bg-zinc-950 text-white hover:bg-zinc-900"
        ></SectionAccordion>
        <SectionImage
            v-if="isLoggedIn && !!currentUser?.has_aaa_membership && currentUser?.aaa_community_discord_link"
            color="even:bg-zinc-50"
            heading="Join the Discord Community"
            headingColor="text-4xl font-semibold tracking-tight sm:text-6xl"
            content="<p>Don't forget, you can now join the Andy Guitar Online Community on Discord. Come and share your progress, get updates on what’s happening and connect with other members.</p>"
            :image="$config.public.uploads + 'images-general/guitar-breaks-may-2022-best-1.jpeg'"
            imageAlt="A group of andy guitar community members at in person meet up"
            :flip="true"
            buttonText="Join Discord"
            :buttonHref="currentUser?.aaa_community_discord_link"
            buttonColor="bg-amber-700 text-white hover:bg-amber-600 focus-visible:outline-amber-600"
        ></SectionImage>
        <SectionJumbo
            :image="$config.public.uploads + 'images-general/screen-shot-2021-05-04-at-13.png'"
            imageAlt="Andy playing his signature acoustic guitar with using his signature capo"
            imageOpacity="opacity-30"
            heading="Signature Products"
            headingComponent="h2"
            headingColor="text-zinc-50"
            content="<p>Check out my signature guitar, ukulele, capo and more at the Andy Guitar Web Store</p>"
            contentColor="text-zinc-50"
            buttonText="Shop"
            buttonHref="https://shop.andyguitar.co.uk/collections/signature-collection"
            buttonTarget="_blank"
            :textCentered="false"
            imageLoading="lazy"
            fetchPriority="low"
        ></SectionJumbo>
    </Sections>
</template>

<script setup>
const {isLoggedIn, currentUser} = storeToRefs(useStoreAuth());

const {featuredCourse, featuredCourseProgress} = storeToRefs(useStoreContinue());
const {getContinue} = useStoreContinue();
const {viewed} = storeToRefs(useStoreViewed());
const {getViews} = useStoreViewed();

const {userLessons} = storeToRefs(useStoreUserLessons());
const {getUserLessons} = useStoreUserLessons();

const {lessons: songsRecent} = storeToRefs(useStoreSongsRecent());
const {getLessons: getSongsRecent} = useStoreSongsRecent();

const {pathway: beginnerAcousticPathway} = storeToRefs(useStorePathwayBeginnerAcoustic());
const {getPathway: getPathwayBeginnerAcoustic} = useStorePathwayBeginnerAcoustic();

const {pathway: intermediateAcousticPathway} = storeToRefs(useStorePathwayIntermediateAcoustic());
const {getPathway: getPathwayIntermediateAcoustic} = useStorePathwayIntermediateAcoustic();

const {lessons: absoluteBeginnerGuitarLessons} = storeToRefs(useStoreAbsoluteBeginnerGuitar());
const {getAbsoluteBeginnerGuitarLessons} = useStoreAbsoluteBeginnerGuitar();

const {lessons: liveReplays} = storeToRefs(useStoreLiveReplays());
const {getLessons: getLiveReplays} = useStoreLiveReplays();

const {liveNow, streams} = storeToRefs(useStoreStreams());
const {getStreams} = useStoreStreams();

const {testimonials} = storeToRefs(useStoreTestimonials());

const {questions} = storeToRefs(useStoreFaqs());

function welcomeAsRequired () {
    if (currentUser.value?.user_welcome) {
        useRouter().replace('/welcome');
    }
}

await useLazyAsyncData('load', async () => {
    const promises = [];

    promises.push(
        getSongsRecent(),
        getPathwayBeginnerAcoustic(),
        getPathwayIntermediateAcoustic(),
        getAbsoluteBeginnerGuitarLessons(),
        getLiveReplays(),
        getStreams(),
    );

    if (isLoggedIn.value) {
        promises.push(getContinue());
        promises.push(getViews());
        promises.push(getUserLessons());
    }
    await Promise.all(promises);
    return true;
});

onBeforeMount(() => {
    watch(currentUser, welcomeAsRequired, {immediate: true});
});

const {$config} = useNuxtApp();
useSeo().buildHead({
    title: $config.public.siteName + ' | Beginner Guitar Lessons',
    description: $config.public.siteStrapline,
    index: true,
});
</script>
