export const useStoreFaqs = defineStore('StoreFaqs', {
    state: () => ({
        questions: [
            {
                heading: 'What is Andy Guitar?',
                content: '<ArticleAnswerWhatAndy />',
            },
            {
                heading: 'Who is Andy Guitar for?',
                content: '<ArticleAnswerWhyAndy />',
            },
            {
                heading: 'Why should I create an account?',
                content: '<ArticleAnswerWhyAccount />',
            },
            {
                heading: 'What is an Access All Areas Membership?',
                content: '<ArticleAnswerWhatAaa />',
            },
            {
                heading: 'How much does Andy Guitar cost?',
                content: '<ArticleAnswerHowMuch />',
            },
            {
                heading: 'Can I talk to Andy?',
                content: '<ArticleAnswerTalkAndy />',
            },
            {
                heading: 'How can I watch?',
                content: '<ArticleAnswerHowWatch />',
            },
            {
                heading: 'How do I cancel?',
                content: '<ArticleAnswerHowCancelFaq />',
            },
        ],
    }),
});
