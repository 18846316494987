<template>
    <SectionCardsSwiper
        v-if="lessons?.length"
        id="section-absolute-beginner-guitar"
        :contentful="true"
        :color="color"
        heading="Absolute Beginner Guitar"
        headingComponent="h2"
        :cards="lessons.map(lesson => ({
            heading: lesson.lesson_title,
            image: lesson.lesson_image_url,
            imageAlt: 'A thumbnail image from one of Andy\'s Absolute Beginner Guitar lessons',
            route: useContextLesson().route(lesson, 'home-absolute-beginner-guitar', lesson.course_slug),
            imageAspectRatio: 'aspect-[40/21] object-cover object-center',
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionAbsoluteBeginnerGuitar',
    props: {
        lessons: {
            required: false,
            type: Array,
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
    },
};
</script>
