<template>
    <div class="section-biscuits" :class="color" v-if="showAppCookies()">
        <LayoutContained :class="padding">
            <ContentText
                :html="cookieNotice"
                :contentColor="contentColor"
                :textMaxWidth="true"
                :textCentered="textCentered"
            ></ContentText>
            <div class="mt-4 mb-1 sm:mb-2 flex flex-row space-x-3" :class="!!textCentered ? 'justify-center' : ''">
                <ButtonPrimary
                    buttonColor="bg-zinc-100 hover:bg-white text-zinc-900"
                    buttonText="Accept"
                    :buttonClick="acceptDefaultCookies"
                ></ButtonPrimary>
                <ButtonPrimary
                    buttonColor="bg-black hover:bg-zinc-950 text-zinc-50 border border-black"
                    buttonText="Reject"
                    :buttonClick="rejectDefaultCookies"
                ></ButtonPrimary>
            </div>
        </LayoutContained>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';

export default {
    name: 'SectionCookies',
    props: {
        padding: {
            required: false,
            default: 'py-5 sm:py-6',
            type: String,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-800',
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-zinc-100',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    computed: {
        ...mapState(useStoreCookies, {
            cookies: 'cookies',
            cookieNotice: 'cookieNotice',
        }),
    },
    methods: {
        ...mapActions(useStoreCookies, {
            acceptDefaultCookies: 'acceptDefaultCookies',
            enableCookie: 'enableCookie',
        }),
        showAppCookies: function () {
            return !this.cookies.asked;
        },
        rejectDefaultCookies: function () {
            this.enableCookie('asked');
        },
    },
};
</script>
