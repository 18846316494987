<template>
    <SectionCardsSwiper
        v-if="lessons?.length"
        id="section-songs-recent"
        :color="color"
        heading="New Videos"
        headingComponent="h2"
        viewAllRoute="/videos"
        :cards="lessons.map(lesson => ({
            route: useContextLesson().route(lesson, 'home-songs-recent'),
            image: lesson.lesson_image_url,
            heading: lesson.lesson_title,
            imageAlt: 'A thumbnail image from a video lesson recently released on Andy Guitar.',
            imageAspectRatio: 'aspect-[40/21] object-cover object-center',
            // imageOverlayLocked: lesson.lesson_locked,
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionSongsRecent',
    props: {
        lessons: {
            required: false,
            type: Array,
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
    },
};
</script>
