<template>
    <SectionCardsSwiper
        v-if="pathway?.pathway_courses?.length"
        id="section-pathway-intermediate-acoustic"
        badgeText="New!"
        badgeColor="bg-green-600 text-white"
        :color="color"
        :heading="pathway?.pathway_name"
        headingComponent="h2"
        viewAllRoute="/courses"
        :cards="pathway?.pathway_courses.map(course => ({
            route: useContextCourse().route(course, pathway?.pathway_slug),
            image: course.course_thumb_url,
            imageAspectRatio: 'aspect-[40/21]',
            heading: course.course_title,
            headingComponent: 'p',
            imageAlt: 'A thumbnail image from one of the modules from Andy Guitar\'s Intermediate Acoustic Guitar Course'
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionPathwayIntermediateAcoustic',
    props: {
        pathway: {
            required: false,
            type: Object,
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
    },
};
</script>
