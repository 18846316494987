export const useStoreSongsRecent = defineStore('StoreSongsRecent', {
    state: () => ({
        lessons: null,
        error: null,
        filter: {
            fields: {
                lesson_category: 'Song'
            },
            paginate: {
                start: 0,
                limit: 36,
            },
            order: [
                {field: 'lesson_created', asc: false},
                {field: 'id_lesson', asc: false},
            ],
        }
    }),
    actions: {
        getLessons: async function () {
            this.error = null;
            ({lessons: this.lessons, error: this.error} = await useQueryLessons().get(this.filter));
        },
    },
});
