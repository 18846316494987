<template>
    <SectionCardsSwiper
        v-if="lessons?.length"
        id="keep-practicing-swiper"
        :color="color"
        heading="Keep Practicing"
        :cards="lessons.map(lesson => ({
            route: useContextLesson().route(lesson, 'continue', lesson.course_slug),
            condensed: true,
            archetype: lesson.archetype,
            progress: lesson.archetype === 'Course' ? (lesson.progress_percent * 100).toFixed(0) : null,
            heading: lesson.archetype === 'Course' ? lesson.course_title : lesson.lesson_title,
            content: lesson.archetype === 'Course' ? lesson.lesson_title : null,
            content_truncate: true,
            image: lesson.lesson_image_url,
            imageAlt: 'A thumbnail image from a lesson that a user has recently watched',
            imageAspectRatio: 'aspect-[40/21] object-cover object-center',
            imageOverlayLocked: lesson.lesson_locked,
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionKeepPracticing',
    props: {
        lessons: {
            required: false,
            type: Array,
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
    },
};
</script>
