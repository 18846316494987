<template>
    <div
        role="progressbar"
        :aria-valuenow="value"
        class="relative h-3 w-full overflow-hidden rounded-lg bg-transparent"
    >
        <div class="absolute inset-0 bg-sky-700 opacity-100 transition-all" :style="style"></div>
        <div class="absolute inset-0 w-full bg-sky-700 opacity-20"></div>
    </div>
</template>

<script>

export default {
    name: 'ContentProgressLinear',
    props: {
        value: {
            required: true,
            type: [Number, String],
            default: 0
        },
    },
    computed: {
        style () {
            return {
                'width': this.value + '%'
            };
        }
    }
};

</script>
