export const useStorePathwayBeginnerAcoustic = defineStore('StorePathwayBeginnerAcoustic', {
    state: () => ({
        pathway: null,
        error: null,
        filter: {
            fields: {
                pathway_slug: 'beginner-acoustic'
            },
        },
    }),
    actions: {
        getPathway: async function () {
            this.error = null;
            const {pathways, error} = await useQueryPathways().get(this.filter);
            this.error = error;
            this.pathway = pathways[0];
        },
        reset: function () {
            this.pathway = null;
            this.error = null;
        },
    },
});
