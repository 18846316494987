<template>
    <SectionCardsSwiper
        v-if="lessons?.length"
        id="home-user-lessons"
        :color="color"
        heading="My List"
        :viewAllRoute="lessons?.length >=5 ? 'my-list' : null"
        :cards="lessons.map(lesson => ({
            route: useContextLesson().route(lesson, 'home-user-lessons'),
            heading: lesson.lesson_title,
            imageAlt: 'A thumbnail image from a lesson that a user has saved to their list',
            imageAspectRatio: 'aspect-[40/21] object-cover object-center',
            image: lesson.lesson_image_url,
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionMyList',
    props: {
        lessons: {
            required: false,
            type: Array,
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
    },
};
</script>
